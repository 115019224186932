exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applications-js": () => import("./../../../src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customersatisfaction-js": () => import("./../../../src/pages/customersatisfaction.js" /* webpackChunkName: "component---src-pages-customersatisfaction-js" */),
  "component---src-pages-employeeevaluation-js": () => import("./../../../src/pages/employeeevaluation.js" /* webpackChunkName: "component---src-pages-employeeevaluation-js" */),
  "component---src-pages-employeesatisfaction-js": () => import("./../../../src/pages/employeesatisfaction.js" /* webpackChunkName: "component---src-pages-employeesatisfaction-js" */),
  "component---src-pages-gdprservice-js": () => import("./../../../src/pages/gdprservice.js" /* webpackChunkName: "component---src-pages-gdprservice-js" */),
  "component---src-pages-how-js": () => import("./../../../src/pages/how.js" /* webpackChunkName: "component---src-pages-how-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketresearch-js": () => import("./../../../src/pages/marketresearch.js" /* webpackChunkName: "component---src-pages-marketresearch-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-surveysetup-js": () => import("./../../../src/pages/surveysetup.js" /* webpackChunkName: "component---src-pages-surveysetup-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

